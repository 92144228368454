import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
import Link from '@utility/Link'
import styles from './styles'

const ButtonPrimary = ({
  to,
  children,
  className,
  color,
  ...rest
}) => (
  <Link
    to={to}
    className={cn(className, styles.btn, {[styles.white]: color === 'white'})}
    {...rest}
  >
    <span>{children}</span>
  </Link>
)

ButtonPrimary.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
}

ButtonPrimary.defaultProps = {
  className: '',
  color: ''
}

export default ButtonPrimary
