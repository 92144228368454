import React, { useContext } from 'react'
import LocaleContext from '@context/LocaleContext'
import Layout from '@components/Layout'
import SEO from '@components/seo'
import PageTitle from '@components/PageTitle'
import ButtonPrimary from '@components/ButtonPrimary'

const NotFoundPage = () => {
  const { code } = useContext(LocaleContext)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <PageTitle>
        { code === "jp" ? "エラー" : "Error" }
      </PageTitle>
      <div className="bg-white">
        <div className="wrapper pt-16 pb-32">
          <div className="row">
            <div className="col-12 md:col-4 lg:col-3 lg:offset-1 mb-8">
              <h2 className="type-660 md:type-760">
                { code === "jp" ? "お探しのページは見つかりませんでした。" : "The page you are looking for doesn’t exist." }
              </h2>
            </div>
            <div className="col-12 md:col-7 md:offset-1 lg:col-6">
              { code === "jp" ?
                <p className="type-230-jp leading-relaxed">お手数ですが、下部よりホームページにお戻りください。</p> :
                <p className="type-330 leading-relaxed">Sorry for the inconvenience. Please return to the homepage.</p>
              }
              <ButtonPrimary to="/" className="inline-block mt-12">
                { code === "jp" ? "ホームページに戻る" : "Go to homepage" }
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
