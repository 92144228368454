import React from 'react'
import styles from './styles'

const PageTitle = ({ children }) => (
  <div className={styles.hero}>
    <div className="wrapper">
      <div className="row">
        <div className="col-12 lg:col-10 lg:offset-1">
          <h1 className={styles.title}>{children}</h1>
        </div>
      </div>
    </div>
  </div>
)

export default PageTitle
